<template>
    <div style="background-color:#FFFFFF">
        <nav class="navbar pt-2 pb-2 pl-5 pr-5 sticky-top navbar-expand-lg navbar-light text-light sbg-blue">
        <img width="150" src="../assets/logoussnewnotext.png"/>
        <ul class="navbar-nav ml-auto" style="margin-top:-3px">
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/homepage')">Beranda</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/transaction')">Status Pembelian</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar-active" @click="toPage('/administration')">Hasil Try Out</a>
            </li>
            <li class="nav-item dropdown">
                <a style="text-transform: capitalize;" class="sbn-navbar-invert dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hai, {{display.fullName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/profile')">Profil</a>
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/');removeAnyStoredInformation()">Keluar</a>
                </div>
            </li>
        </ul>
        </nav>
        <b-container class="mt-5">
            <b-row  align-h="center">
                <b-col sm="12">
                    <span>
                    <div class="card p-3" style="border-radius:10px;">
                        <div class="row">
                            <div class="col-3">
                                <button style="width:100%" class="sbtn-blue">Pilih Kode Paket User</button>
                            </div>
                            <div class="col-9">
                                <!-- <b-form-select v-on:change="getUniversity();getUserSubPackage(selectedUserPackage);getSubPackageRawList(selectedUserPackage);getPackageWeightFactorStatus()" v-model="selectedUserPackage" style="color:#3B73C5;font-size:14px;width:100%;height:100%;" size="sm" :options="userPackageData"></b-form-select> -->
                                <b-form-select v-on:change="getAll()" v-model="selectedUserPackage" style="color:#3B73C5;font-size:14px;width:100%;height:100%;" size="sm" :options="userPackageData"></b-form-select>
                            
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3 p-3" style="border-radius:10px;">
                        <b-tabs class="nav-pills">
                            <b-tab v-for="d in userAdministrationTab" :key="d.userAdministrationOrder">
                            <template v-slot:title>
                                {{d.userAdministrationDescription}}
                            </template>
                            <div class="mt-3">
                                <div v-show="d.userAdministrationSegment=='SUBPACKAGETIME'">
                                    <b-table class="stbl" borderless :items="userSubPackageList" :fields="userSubPackageFilter">
                                        
                                        <template v-slot:cell(subPackageExamDuration)="data">
                                            {{getFormatTime(data.item.subPackageExamDuration)}} {{data.item.subPackageExamDuration > 60 ? 'Menit' : 'Detik'}}
                                        </template>
                                    </b-table>
                                </div>
                                <span v-show="showUserPackageScore==0">
                                    <b-card v-show="d.userAdministrationSegment=='ANSWER'" style="font-size:14px" class="mb-3">
                                        <h6>Jawaban belum dapat dicek sampai dengan periode Try Out selesai / Mencapai jumlah minimum peserta yang ditentukan.</h6>
                                    </b-card>
                                </span>
                                <div  v-show="d.userAdministrationSegment=='ANSWER'&&showUserPackageScore==1">
                                    <b-tabs pills small card>
                                        <b-tab v-for="d in userSubPackageUserAnswerCombinedList" :key="d.subPackageID">
                                            <template v-slot:title>
                                                {{d.subPackageName}}
                                            </template>
                                            <b-table style="font-size:12px" bordered small :items="d.userAnswerList" :fields="userAnswerFilter">
                                                <template v-slot:cell(questionOrder)="data">
                                                    <div class="p-1">{{data.item.questionOrder}}</div>
                                                </template>
                                                <template v-slot:cell(questionAnswerAlphabeticalOrder)="data">
                                                    <div class="p-1">{{data.item.questionAnswerAlphabeticalOrder}}</div>
                                                </template>
                                                <template v-slot:cell(userAnswerAlphabeticalOrder)="data">
                                                    <div class="p-1">{{data.item.userAnswerAlphabeticalOrder == null ? 'Kamu tidak menjawab' : data.item.userAnswerAlphabeticalOrder}}</div>
                                                </template>
                                                <template v-slot:cell(userAnswerStatus)="data">
                                                    <div v-show="data.item.userAnswerID == data.item.questionAnswerID" class="text-left p-1 bg-success text-white">{{data.item.userAnswerID == data.item.questionAnswerID ? 'Benar' : 'Salah'}}</div>
                                                    <div v-show="data.item.userAnswerID != data.item.questionAnswerID" class="text-left p-1 bg-danger text-white">{{data.item.userAnswerID == data.item.questionAnswerID ? 'Benar' : 'Salah'}}</div>
                                                </template>
                                            </b-table>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                                <span v-show="showUserPackageScore==0">
                                    <b-card v-show="d.userAdministrationSegment=='SCORE'||d.userAdministrationSegment=='SUMMARYSCORE'" style="font-size:14px" class="mb-3">
                                        <h6>Hasil Try Out belum dapat dicek sampai dengan periode Try Out selesai / Mencapai jumlah minimum peserta yang ditentukan.</h6>
                                    </b-card>
                                </span>
                                <span v-show="showUserPackageScore==1">
                                    <b-card v-show="d.userAdministrationSegment=='SCORE'||d.userAdministrationSegment=='SUMMARYSCORE'" style="font-size:14px" class="mb-3">
                                        <div v-show="selectedUserPackageType=='SAINTEK'">
                                            <b-row class="mb-2">
                                                <b-col class="text-left pt-1" sm="2">
                                                    Perguruan Tinggi Negeri
                                                </b-col>
                                                <b-col sm="10">
                                                    <b-form-select v-on:change="getStudyProgram('SAINTEK')" v-model="form.universityID" size="sm" :options="universityData"></b-form-select>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="text-left pt-1" sm="2">
                                                    Prodi
                                                </b-col>
                                                <b-col sm="10">
                                                    <b-form-select v-on:change="getUserScore(form,'SAINTEK');addSelection()" v-model="form.studyProgramID" size="sm" :options="studyProgramData"></b-form-select>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div v-show="selectedUserPackageType=='SOSHUM'">
                                            <b-row class="mb-2">
                                                <b-col class="text-left pt-1" sm="2">
                                                    Perguruan Tinggi Negeri
                                                </b-col>
                                                <b-col sm="10">
                                                    <b-form-select v-on:change="getStudyProgram('SOSHUM')" v-model="form.secondaryUniversityID" size="sm" :options="universityData"></b-form-select>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="text-left pt-1" sm="2">
                                                    Prodi
                                                </b-col>
                                                <b-col sm="10">
                                                    <b-form-select v-on:change="getUserScore(form,'SOSHUM');addSelection()" v-model="form.secondaryStudyProgramID" size="sm" :options="studyProgramData"></b-form-select>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-card>
                                    <b-table v-show="d.userAdministrationSegment=='SCORE'" style="font-size:14px" bordered small :items="userScoreData" :fields="tableFilter">
                                        <template v-slot:cell(subPackageDescription)="data">
                                            <div class="text-left">{{data.item.subPackageDescription}}</div>
                                        </template>
                                        <template v-slot:cell(firstClusterValue)="data">
                                            {{data.item.firstClusterValue}}%
                                        </template>
                                    </b-table>
                                    <b-table v-show="d.userAdministrationSegment=='SUMMARYSCORE'" style="font-size:14px" bordered small :items="userSummaryScoreData" :fields="summaryScoreFilter">
                                    </b-table>
                                    <b-card v-show="d.userAdministrationSegment=='SUMMARYSCORE'">
                                        <b>Riwayat Pilihan Prodi</b>
                                        <b-table  style="font-size:14px;margin-top:10px;" bordered small :items="selectedStudyProgram"  :fields="summaryScoreFilter">
                                        </b-table>
                                    </b-card>
                                </span>
                            </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    </span>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
export default {
    methods:{
        getAll(){
            //University
            this.selectedStudyProgram = [];
            let userPackageID = this.selectedUserPackage;
            let ref = this;
            let selectedType;
            axios  
                .get("https://backend.uss-utbk.com/n-userpackagebyuserpackageid",{
                    params: {
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    let data = res.data.values[0];
                    console.log(data.userPackageCode);
                    if(data.userPackageCode.includes('STK')){
                        ref.selectedUserPackageType = 'SAINTEK';
                        selectedType = 'SAINTEK';
                        axios  
                        .get("https://backend.uss-utbk.com/n-universitysaintekdropdown")
                        .then(res => {
                            this.universityData = res.data.values;
                            this.getPackageWeightFactorStatus(selectedType);
                            this.getStudyProgram(selectedType);
                        });
                    }  
                    else {
                        ref.selectedUserPackageType = 'SOSHUM';
                        selectedType = 'SOSHUM';
                        axios  
                        .get("https://backend.uss-utbk.com/n-universitysoshumdropdown")
                        .then(res => {
                            this.universityData = res.data.values;
                            this.getPackageWeightFactorStatus(selectedType);
                            this.getStudyProgram(selectedType);
                        });
                    }
                });
                this.getUserSubPackage(this.selectedUserPackage);
                this.getSubPackageRawList(this.selectedUserPackage);
        },  
        getUserSubPackage(userPackageID){
            axios  
                .get("https://backend.uss-utbk.com/n-usersubpackage",{
                    params: {
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.userSubPackageList = res.data.values;  
                });
        },
        getFormatTime(param) {
            let time = param
            var hr = ~~(time / 3600);
            var min = ~~((time % 3600) / 60);
            var sec = time % 60;
            var sec_min = "";
            if (hr > 0) {
               sec_min += "" + hr + ":" + (min < 10 ? "0" : "");
            }
            sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
            sec_min += "" + sec;
            return sec_min.replace(":00", "");
        },
        addSelection(){
            let test = this;
            if(test.selectedStudyProgram.length == 5){
                test.selectedStudyProgram.shift();
                test.selectedStudyProgram.push(test.userSummaryScoreData[0]);
            }
            else {
                test.selectedStudyProgram.push(test.userSummaryScoreData[0]);
            }
        },
        getSubPackageRawList(userPackageID){
        let userID = localStorage.getItem("userID");
            axios  
                .get("https://backend.uss-utbk.com/subpackagebyuserpackageid",{
                    params: {
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.subPackageRawList = res.data.values;  
                    this.getUserAnswerRawList(userPackageID, userID);
                });
        },
        
        getUserAnswerRawList(userPackageID,userID){

            axios  
                .get("https://backend.uss-utbk.com/useranswer",{
                    params: {
                        userPackageID: userPackageID,
                        userID: userID
                    }
                })
                .then(res => {
                    this.userAnswerRawList = res.data.values;  
                    this.combineSubPackageWithUserAnswer();
                });
        },
        getPackageWeightFactorStatus(selectedType){
            let userPackageID = this.selectedUserPackage;
            let ref = this;
            axios  
            .get("https://backend.uss-utbk.com/checkpackageweightfactor",{
                params: {
                    userPackageID: userPackageID
                }
            })
            .then(res => {
                let packageWeightFactorData = res.data.values;
                let packageWeightFactorStatus = packageWeightFactorData[0].packageWeightFactorCalculated;
                console.log(packageWeightFactorStatus);
                this.showUserPackageScore = packageWeightFactorStatus;
                this.getUserScore(ref.form,selectedType);
            })
        },
        getUserScore(form,selectedType){
            let userID = localStorage.getItem("userID");
            let userPackageID = this.selectedUserPackage;
            console.log(selectedType);
            if(selectedType=='SAINTEK'){
                axios  
                .get("https://backend.uss-utbk.com/userscore",{
                    params: {
                        userID: userID,
                        universityID: form.universityID,
                        studyProgramID: form.studyProgramID,
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.userScoreData = res.data.values;
                    console.log(this.userScoreData);
                })
                axios  
                .get("https://backend.uss-utbk.com/usersummaryscore",{
                    params: {
                        userID : userID,
                        universityID : form.universityID,
                        studyProgramID : form.studyProgramID,
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.userSummaryScoreData = res.data.values;
                })
            }
            else {
                axios  
                .get("https://backend.uss-utbk.com/userscore",{
                    params: {
                        userID: userID,
                        universityID: form.secondaryUniversityID,
                        studyProgramID: form.secondaryStudyProgramID,
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.userScoreData = res.data.values;
                    console.log(this.userScoreData);
                })
                axios  
                .get("https://backend.uss-utbk.com/usersummaryscore",{
                    params: {
                        userID : userID,
                        universityID : form.secondaryUniversityID,
                        studyProgramID : form.secondaryStudyProgramID,
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    this.userSummaryScoreData = res.data.values;
                })
            }
        },
        combineSubPackageWithUserAnswer(){
            
            let subPackageID = "subPackageID"
            this.subPackageRawList.sort((a, b) => {
                if (a[subPackageID] < b[subPackageID]) {
                    return -1;
                }
                if (a[subPackageID] > b[subPackageID]) {
                    return 1;
                }
                return 0;
            })
            let subPackageMap = this.subPackageRawList.reduce((map, row) => {
                let key = row["subPackageID"]; 
                map[key] = row;
                return map;
            }, {})

            let userAnswerMap = this.userAnswerRawList.reduce((map, row) => { 
                let key = row["subPackageID"];
                if (map[key]) { 
                    if (!map[key].userAnswerList) map[key].userAnswerList = [];
                    map[key].userAnswerList.push(row);
                }
                return map;
            }, subPackageMap)

            this.userSubPackageUserAnswerCombinedList = Object.values(userAnswerMap);
        },
        getStudyProgram(type){
            let universityID;
            if(type=='SAINTEK'){
                universityID = this.form.universityID
                axios
                .get("https://backend.uss-utbk.com/n-studyprogramdepartmentuniversity",{
                    params: {
                        universityID: universityID,
                        departmentID: 1
                    }
                })
                .then(res => {
                    this.studyProgramData = res.data.values;
                });
            }
            else {
                universityID = this.form.secondaryUniversityID
                axios
                .get("https://backend.uss-utbk.com/n-studyprogramdepartmentuniversity",{
                    params: {
                        universityID: universityID,
                        departmentID: 2
                    }
                })
                .then(res => {
                    this.studyProgramData = res.data.values;
                });
            }
        },
        toPage(param) {
            this.$router.push(param);
        },
        removeAnyStoredInformation(){
            localStorage.removeItem("userID");
            localStorage.removeItem("userPackageID");
            localStorage.removeItem("packageID");
        }
    },
    computed: {
    },
    created(){
        let userID = localStorage.getItem("userID");
        if(localStorage.getItem("userID") == null){
            this.$router.push("/");
        }
        axios
        .get("https://backend.uss-utbk.com/userinformation",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            this.userData = res.data.values;
            let userData = res.data.values;
            this.display.fullName = userData[0].userFullName;
            this.form.universityID = userData[0].universityID;
            this.form.studyProgramID = userData[0].studyProgramID;
            this.form.secondaryUniversityID = userData[0].secondaryUniversityID;
            this.form.secondaryStudyProgramID = userData[0].secondaryStudyProgramID;
        });
        axios  
        .get("https://backend.uss-utbk.com/userpackagedropdown",{
            params: {
                userID: userID,
                userPackageStatus: 'COMPLETED'
            }
        })
        .then(res => {
            this.userPackageData = res.data.values;
        });
        axios  
        .get("https://backend.uss-utbk.com/userscore",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            this.userScoreData = res.data.values;
        });
    },
    
    data(){
        return {
            selectedUserPackageType: null,
            userSubPackageList: [],
            selectedStudyProgram: [],
            showUserPackageScore: null,
            selectedUserPackage: '',
            subPackageRawList: [],
            userAnswerRawList: [],
            userSubPackageUserAnswerCombinedList: [],
            userPackageData: [],
            userSubPackageFilter: [
                {
                    key: 'subPackageExamOrder', label: 'No.', sortable: true
                },
                {
                    key: 'subPackageDescription', label: 'Sub Test', sortable: true
                },
                {
                    key: 'subPackageExamDuration', label: 'Durasi Pengerjaan', sortable: true
                }
            ],
            userAdministrationTab:[
                {
                    userAdministrationOrder: 1,
                    userAdministrationSegment: 'ANSWER',
                    userAdministrationDescription: 'Cek Jawaban'
                },
                {
                    userAdministrationOrder: 2,
                    userAdministrationSegment: 'SUBPACKAGETIME',
                    userAdministrationDescription: 'Cek Waktu Penyelesaian'
                },
                {
                    userAdministrationOrder: 3,
                    userAdministrationSegment: 'SCORE',
                    userAdministrationDescription: 'Cek Detail Hasil'
                },
                {
                    userAdministrationOrder: 4,
                    userAdministrationSegment: 'SUMMARYSCORE',
                    userAdministrationDescription: 'Cek Hasil Akhir'
                }
            ],
            currentRoute: (this.$router.currentRoute.path).replace("/",""),
            form: {
                universityID : null,
                studyProgramID : null,
                secondaryUniversityID : null,
                secondaryStudyProgramID : null
            },
            display: {
                fullName: ''
            },
            packageID: '',
            universityID: '',
            userScoreData: [],
            userSummaryScoreData: [],
            packageList: [
                {
                    value: 1, text: 'SAINTEK'
                },
                {
                    value: 3, text: 'TPS SAINTEK'
                },
                {
                    value: 2, text: 'SOSHUM'
                },
                {
                    value: 4, text: 'TPS SOSHUM'
                }
            ],
            userAnswerFilter: [
                {
                    key: 'questionOrder', label: 'No. Soal', sortable: true
                },
                {
                    key: 'userAnswerAlphabeticalOrder', label: 'Jawaban Kamu', sortable: true
                },
                {
                    key: 'questionAnswerAlphabeticalOrder', label: 'Kunci Jawaban', sortable: true
                },
                {
                    key: 'userAnswerStatus', label: 'Benar/Salah', sortable: true
                }
            ],
            tableFilter: [
                {
                    key: 'subPackageDescription', label: 'Sub Test', sortable: true
                },
                {
                    key: 'subPackageNationalScore', label: 'Nilai Nasional Siswa', sortable: true
                }
            ],
            selectedStudyProgramFilter: [
                {
                    key: 'universityName', label: 'Perguruan Tinggi Negeri'
                },
                {
                    key: 'studyProgramDescription', label: 'Prodi'
                }
            ],
            summaryScoreFilter: [
                {
                    key: 'universityName', label: 'Perguruan Tinggi Negeri', sortable: true
                },
                {
                    key: 'studyProgramDescription', label: 'Prodi', sortable: true
                },
                {
                    key: 'studyProgramMinValue', label: 'Nilai Minimum Prodi', sortable: true
                },
                {
                    key: 'nationalAverageScore', label: 'Nilai Rata - Rata (Nasional) Siswa', sortable: true
                },
                {
                    key: 'clusterScore', label: 'Nilai Klaster Siswa', sortable: true
                },
                {
                    key: 'passStatusAverage', label: 'Lulus (Nilai Rata - Rata)', sortable: true
                },
                {
                    key: 'passStatusCluster', label: 'Lulus (Nilai Klaster)', sortable: true
                }
            ],
            universityData: [],
            studyProgramData: []
        }
    }
}
</script>


<style scoped>

.sbtn-blue {
    background-color: #3B73C5;
    color: #FFF;
    border-radius: 10px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    border: none;
    text-align: left;
}
.body {
  background-color: #F3F7FA;
}

.sbg-blue {
  background-color: #3B73C5;
}

.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
/deep/ .stbl tbody td {
    border-bottom: 1px solid #F3F7FA;
    padding: 5px;
}
.stbl {
    font-size:14px;
}
</style>