<template>
    <!-- <div style="background-color:#fffde5"> -->
    <div class="body" style="height:100%;width:100%;">
    <div class="pb-4 body">
        <nav class="navbar pt-2 pb-2 pl-5 pr-5 sticky-top navbar-expand-lg navbar-light text-light sbg-blue">
        <img width="150" src="../assets/logoussnewnotext.png"/>
        <ul class="navbar-nav ml-auto" style="margin-top:-3px">
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/homepage')">Beranda</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar-active" @click="toPage('/transaction')">Status Pembelian</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/administration')">Hasil Try Out</a>
            </li>
            <li class="nav-item dropdown">
                <a style="text-transform: capitalize;" class="sbn-navbar-invert dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hai, {{display.fullName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/profile')">Profil</a>
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/');removeAnyStoredInformation()">Keluar</a>
                </div>
            </li>
        </ul>
        </nav>
        <b-container>
            <div class="mt-5" style="">
                
                    <div class="card srd-25 p-4">
                    <b-tabs class="nav-pills">
                        <b-tab v-for="d in userPackageGroupData" :key="d.userPackageStatusOrder">
                        <template v-slot:title>
                            {{d.userPackageDescription}}
                        </template>
                            <!-- <span v-for="i in d.userPackageList" :key="i">
                                <div class="card p-3 mt-2" style="border-radius:15px;">
                                    <div class="row">
                                        <div class="col-1 sfs-20">
                                            {{i.userPackageOrderDisplay}} 
                                        </div>
                                        <div class="col-3 sfs-20">
                                            {{i.userPackageCode}} 
                                        </div>
                                        <div class="col-2 sfs-20">
                                            {{i.packageDescription}} 
                                        </div>
                                        <div class="col-2 sfs-20">
                                            Rp {{formatNumber(i.userPackageTotalPrice)}},-
                                        </div>
                                        <div class="col-2 sfs-20">
                                            {{i.userPackagePaymentOption}} 
                                        </div>
                                        <div class="col-1 sfs-20">
                                            <button @click="orderPackage(d.packageID)" class="btn btn-warning text-white srd-25 p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Bayar</button>
                                        </div>
                                        <div class="col-1 sfs-20">
                                            <button @click="orderPackage(d.packageID)" class="btn btn-warning text-white srd-25 p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Batal</button>
                                        </div>
                                    </div>
                                </div>   
                            </span> -->
                                <b-table class="stbl" borderless :items="d.userPackageList" :fields="filterUserPackageGroupData">
                                    <template v-slot:cell(packagePrice)="data">
                                        Rp{{formatNumber(data.item.packagePrice)}},-
                                    </template>
                                    <template v-slot:cell(userPackageTotalPrice)="data">
                                        Rp{{formatNumber(data.item.userPackageTotalPrice)}},-
                                    </template>
                                    <template v-show="userPackageStatus=='PAID'" v-slot:cell(navButton)="data">
                                        <!-- <button v-show="data.item.userPackageStatus=='UNPAID'" @click="confirmPackage(data.item.userPackageOrder-1)" class="btn sbtn-blue p-1" type="submit" style="width:40%;font-size:14px;font-weight:bold;border-radius:10px;margin-top:-5px">Bayar</button> -->
                                        <div class="row" v-show="data.item.userPackageStatus=='PAID'">
                                            <div class="col-6">
                                                <span class="sbdg-green" v-show="data.item.userPackageCompletion==1&&data.item.userPackageStatus=='PAID'">TPS Done</span>
                                            </div>
                                            <div class="col-6">
                                                <button v-show="data.item.userPackageStatus=='PAID'&&data.item.userPackageCompletion==0" @click="toPage('exam');setUserPackage(data.item.packageID, data.item.userPackageID)" class="btn sbtn-blue p-1" style="width:100%;font-size:14px;margin-left:10px;font-weight:bold;border-radius:10px;margin-top:-5px">Mulai TO</button>
                                                <button v-show="data.item.userPackageStatus=='PAID'&&data.item.userPackageCompletion==1" @click="toPage('exam');setUserPackage(data.item.packageID, data.item.userPackageID)" class="btn sbtn-blue p-1" style="width:100%;font-size:14px;margin-left:10px;font-weight:bold;border-radius:10px;margin-top:-5px">Lanjutkan TO</button>
                                            </div>
                                        </div>
                                        <button v-show="data.item.userPackageStatus=='UNPAID'" @click="confirmPackage(data.item.userPackageOrder-1)" class="btn sbtn-blue p-1" style="width:100%;font-size:14px;margin-left:10px;font-weight:bold;border-radius:10px;margin-top:-5px">Bayar</button>
                                        <!-- <b-button v-show="data.item.userPackageStatus=='UNPAID'" style="margin-top:-5px;font-size:12px" @click="confirmPackage(data.item.userPackageOrder-1)" size="sm" variant="danger">Bayar</b-button>-->
                                        <!-- <b-button v-show="data.item.userPackageStatus=='PAID'" style="margin-top:-5px;font-size:12px" size="sm" variant="success" 
                                        @click="toPage('exam');setUserPackage(data.item.packageID, data.item.userPackageID)">Mulai Tes</b-button> -->
                                    </template>
                                </b-table>
                        </b-tab>
                    </b-tabs>
                
                    </div>
            </div>
        </b-container>
        <b-modal
            id="modal"
            ref="modal"
            title="Pembayaran Paket"
            hide-footer
            centered
            >
            <b-row v-show="confirmPhase==1" align-h="center" >
                        <b-col sm="12">
                            <b-card style="text-align:left;font-size:14px">
                                
                        <div v-show="selectedPackageData.userPackagePaymentOption == 'BANKTRANSFER'">
                            <img width="100" src="../assets/banktransfer.png"/><br/>
                            Untuk menyelesaikan pembayaran, Anda dapat melakukan Transfer ke Rekening berikut :<br/>
                            <b>Nama : Salemba Group</b><br/>
                            <b>No Rek : 1250012826236</b><br/><br/>
                        </div>
                        
                        <div v-show="selectedPackageData.userPackagePaymentOption == 'GOPAY'">
                            <img width="100" src="../assets/gopay.jpg"/><br/>
                            Untuk menyelesaikan pembayaran, Anda dapat melakukan Top Up ke Saldo Gopay berikut :<br/>
                            <b>Nama : Salemba Group</b><br/>
                            <b>Nomor : 0821-1236-6956</b><br/><br/>
                        </div>
                        
                        <div v-show="selectedPackageData.userPackagePaymentOption == 'OVO'">
                            <img width="100" src="../assets/ovo.png"/><br/>
                            Untuk menyelesaikan pembayaran, Anda dapat melakukan Top Up ke Saldo OVO berikut :<br/>
                            <b>Nama : Salemba Group</b><br/>
                            <b>Nomor : 0821-1236-6956</b><br/><br/>
                        </div>
                        
                        Pemesanan Anda atas <b>{{selectedPackageData.packageDescription}}</b> 
                        dengan kode <b>{{selectedPackageData.userPackageCode}}</b>
                        senilai <b>Rp{{selectedPackageData.userPackageTotalPrice}},-</b> 
                        dengan metode pembayaran
                        <b>{{selectedPackageData.userPackagePaymentOption}}</b>
                        masih berstatus 
                        <b>{{selectedPackageData.userPackageStatus}}</b>
                        <br/><br/>
                        Apabila anda telah melakukan pembayaran, silahkan upload bukti pembayaran Anda
                        <br/>
                        Setelah pembayaran disetujui oleh Admin UTBK Salemba Group, Anda dapat langsung 
                        memulai Try Out Online.<br/><br/>
                        
                        <b-form-file
                            v-model="uploadedFile"
                            :state="Boolean(uploadedFile)"
                            placeholder="Unggah Bukti Pembayaran Disini"
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <br/><br/>

                        <b-button @click="updateUserPackageStatus(selectedPackageData.userPackageID)" block size="sm" variant="danger">Unggah</b-button>
                        
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-show="confirmPhase==2" align-h="center" >
                <b-col sm="12">
                    <b-card style="text-align:left;font-size:14px">
                        Bukti pembayaran sudah kami terima, silahkan menunggu konfirmasi dari Admin Salemba Group, terimakasih.
                        
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
    </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    methods:{
        setUserPackage(packageID,userPackageID){
            localStorage.setItem("packageID", packageID);
            localStorage.setItem("userPackageID", userPackageID);
        },
        combinePackageStatusWithUserPackage(){
            
            let userPackageStatus = "userPackageStatus"
            this.packageStatusData.sort((a, b) => {
                if (a[userPackageStatus] < b[userPackageStatus]) {
                    return -1;
                }
                if (a[userPackageStatus] > b[userPackageStatus]) {
                    return 1;
                }
                return 0;
            })
            let userPackageStatusMap = this.packageStatusData.reduce((map, row) => {
                let key = row["userPackageStatus"]; 
                map[key] = row;
                return map;
            }, {})

            let userPackageGroupMap = this.userPackageData.reduce((map, row) => { 
                let key = row["userPackageStatus"];
                if (map[key]) { 
                    if (!map[key].userPackageList) map[key].userPackageList = [];
                    map[key].userPackageList.push(row);
                }
                return map;
            }, userPackageStatusMap)

            this.userPackageGroupData = Object.values(userPackageGroupMap).reverse();
            console.log('Berhasil')
        },
        updateUserPackageStatus(param){
            let userPackageStatus = 'PAID';
            let ref = this;
            this.closeModal();
            console.log(param)
            axios.put('https://backend.uss-utbk.com/userpackage', {
                userPackageID: param,
                userPackageStatus: userPackageStatus
            })
            .then(function (response) {
                console.log(response);
                ref.refreshTable();
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        refreshTable(){
            this.packageStatusData = [
                {
                    userPackageStatusOrder: 1,
                    userPackageStatus: 'UNPAID',
                    userPackageDescription: 'Menunggu Pembayaran'
                },
                {
                    userPackageStatusOrder: 2,
                    userPackageStatus: 'PAID',
                    userPackageDescription: 'Siap Tes'
                },
                {
                    userPackageStatusOrder: 3,
                    userPackageStatus: 'COMPLETED',
                    userPackageDescription: 'Sudah Selesai'
                }
            ],
            this.userPackageGroupData = [];
            this.userPackageData = [];
            this.getPackageData();
        },
        getPackageData(){
            let userID = localStorage.getItem("userID");
            axios
            .get("https://backend.uss-utbk.com/userpackage",{
                params: {
                    userID: userID
                }
            })
            .then(res => {
                this.userPackageData = res.data.values;
                this.combinePackageStatusWithUserPackage();
            });
        },
        toPage(param){
            this.$router.push(param);
        },
        formatNumber(param) {
            return param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        openModal(){
            this.$refs['modal'].show()
        },
        closeModal(){
            this.$refs['modal'].hide()
        },
        removeAnyStoredInformation(){
            localStorage.removeItem("userID");
            localStorage.removeItem("userPackageID");
            localStorage.removeItem("packageID");
        },
        confirmPackage(param){
            this.confirmPhase = 1;
            let selectedPackageData = this.userPackageData[param];
            this.selectedPackageData = selectedPackageData;
            this.openModal();

        }
    },
    data(){
        return {
            confirmPhase: 1,
            uploadedFile: null,
            selectedPackageData: {},
            packageStatusData: [
                {
                    userPackageStatusOrder: 1,
                    userPackageStatus: 'UNPAID',
                    userPackageDescription: 'Menunggu Pembayaran'
                },
                {
                    userPackageStatusOrder: 2,
                    userPackageStatus: 'PAID',
                    userPackageDescription: 'Siap TO'
                },
                {
                    userPackageStatusOrder: 3,
                    userPackageStatus: 'COMPLETED',
                    userPackageDescription: 'Sudah Selesai'
                }
            ],
            userPackageData: [],
            userPackageGroupData: [],
            filterUserPackageGroupData: [
                {
                    key: 'userPackageOrderDisplay', label: 'NO', sortable: false
                },
                {
                    key: 'userPackageCode', label: 'KODE PAKET', sortable: false
                },
                {
                    key: 'packageDescription', label: 'DESKRIPSI', sortable: false
                },
                {
                    key: 'userPackageTotalPrice', label: 'HARGA', sortable: false
                },
                {
                    key: 'navButton', label: '', sortable: false
                }
            ],
            display: {
                fullName: ''
            }
        }
    },
    computed:{
    },
    beforeCreate(){
        let userID = localStorage.getItem("userID");
        if(localStorage.getItem("userID") == null){
            this.$router.push("/");
        }

        axios
        .get("https://backend.uss-utbk.com/userinformation",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            let userData = res.data.values;
            this.display.fullName = userData[0].userFullName;
        });
        
        axios
        .get("https://backend.uss-utbk.com/userpackage",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            this.userPackageData = res.data.values;
            this.combinePackageStatusWithUserPackage();
        });
    }
}
</script>
<style scoped>

.body {
  background-color: #F3F7FA;
}

.sbg-blue {
  background-color: #3B73C5;
}

.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.sbtn-blue {
  color: #FFF;
  background-color: #3B73C5;
}
.sbtn-blue:hover {
  color: #FFF;
  background-color: #1D3962;
  transition: 0.3s;
}
.sbtn-white {
  color: #DF150F;
}
.sbtn-white:hover {
  border: #DF150F 1px solid;
  transition: 0.3s;
  color: #DF150F;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
.sfs-20 {
    font-size:17px;
}
/deep/ .stbl tbody td {
    border-bottom: 1px solid #F3F7FA;
}
.stbl {
    margin-top:20px;
    font-size:12px;
}
.sbdg-green {
    background-color: rgb(67, 207, 62);
    color: #FFF;
    padding: 7px 14px;
    border-radius: 10px;
}
</style>
